import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>I have 2 years of experience in software development and testing, specializing in web and cloud applications using Agile methodologies. Skilled in AWS services, database management, Cypress, Selenium, and CI/CD with Jenkins and GitHub. Proficient in Java, Node.js, Python, and C++, with knowledge in AI (DNNs), cryptographic algorithms, and server management. Experienced in web and mobile development (Vue, HTML, CSS, JavaScript, Flutter) and user-centric design. Familiar with Docker, JUnit, Mockito, Digital Ocean, REST APIs, and cyber security.</p>
                        <Carousel responsive={responsive} infinite={true}
                                  className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image"/>
                                <h5>Web Development</h5>
                                <h5>Vue,React</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image"/>
                                <h5>App Development</h5>
                                <h5>Flutter</h5>
                            </div>
                            <div className="item">
                                <img src={meter1} alt="Image"/>
                                <h5>Quality Assurance</h5>
                                <h5>Cypress,Selenium,Jenkin</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image"/>
                                <h5>Java</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image"/>
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image"/>
                                <h5>AWS</h5>
                                <h5>S3, Lambda, DynamoDB, SQS, EC2</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image"/>
                                <h5>DataBase</h5>
                                <h5>OracleDB, MySQL, SQLite, MongoDB, Redis, Spark, Neo4J</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image"/>
                                <h5>AI</h5>
                            </div>
                            <div className="item">
                            <img src={meter3} alt="Image"/>
                                <h5>UI/UX</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image"/>
    </section>
  )
}
